import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/render/project/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "generate-graphql-schema"
    }}>{`Generate GraphQL Schema`}</h1>
    <p>{`This project uses graphql (specifically, `}<a parentName="p" {...{
        "href": "https://github.com/apollographql/apollo-client"
      }}>{`apollo`}</a>{`) to communicate with the backend. We've enabled linting of graphql queries and mutations in this project (both in your editor and in CI). This check is powered by a schema file found at `}<inlineCode parentName="p">{`./app/src/gql/schema.json`}</inlineCode>{`. Following the steps below you can regenerate that schema file.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <ol>
      <li parentName="ol">{`Ensure that build is running locally`}</li>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`node scripts/generate-graphql-schema`}</inlineCode></li>
    </ol>
    <h2 {...{
      "id": "note"
    }}>{`Note`}</h2>
    <p>{`For performance reasons, eslint is not set up to autoreload configuration files. You will need to restart your editor in order for the newly generated schema file to start producing warnings.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      